import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import { i18n } from './plugins/i18n';
import Fetching from './plugins/fetching';
import vuetify from './plugins/vuetify';
import { pinia } from './plugins/pinia';
import Mitt from './plugins/mitt';
import '@vuepic/vue-datepicker/dist/main.css';
import 'maz-ui/css/main.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/css/common.scss';
import '@/assets/css/styles.scss';
import '@/assets/css/excludedStyles.scss';
import '@/assets/css/tabs.scss';

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        idToken: this.idToken,
        logout: this.logout,
        sentryUrl: this.sentryUrl,
        sentrySampleRate: this.sentrySampleRate,
        ignoredSentryErrors: this.ignoredSentryErrors,
        checkSentryErrors: this.checkSentryErrors,
        isLocal: this.isLocal,
        translations: this.translations,
        arabicTranslation: this.arabicTranslation,
        noNavBar: this.noNavBar,
        checkoutSDKLink: this.checkoutSDKLink,
      });
    },
  },
  handleInstance(app) {
    app.use(router);
    app.use(vuetify);
    app.use(i18n);
    app.use(Fetching);
    app.use(Mitt);
    app.use(pinia);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = [
  vueLifecycles.mount,
  async () => {
    const body = document.body;
    body.querySelector('.v-overlay-container')?.setAttribute('id', 'vuetify-3');

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const addedNodes = Array.from(mutation.addedNodes);
          for (const node of addedNodes) {
            if (
              node.classList &&
              node.classList.contains('v-overlay-container')
            ) {
              node.setAttribute('id', 'vuetify-3');
            }
          }
        }
      }
    });
    observer.observe(body, { childList: true });
  },
];
export const unmount = [
  vueLifecycles.unmount,
  () =>
    Promise.resolve().then(() => {
      const body = document.body;
      body.querySelector('.v-overlay-container')?.removeAttribute('id');
    }),
];
