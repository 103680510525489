import { i18n } from '@/plugins/i18n';

export default {
  path: 'events',
  name: 'events',
  meta: {
    plugin: 'events',
  },
  component: () =>
    import(/* webpackChunkName: "event" */ '@/views/customer/Events'),
  children: [
    {
      path: '',
      name: 'categories-list',
      component: () =>
        import(
          /* webpackChunkName: "events-list" */ '@/views/customer/EventsCategories'
        ),
      meta: {
        plugin: 'events',
        hide_navbar: true,
      },
    },
    {
      path: 'thankyou',
      name: 'thankyou',
      component: () =>
        import(
          /* webpackChunkName: "leave-thanks" */ '@/views/customer/BookingThankYou'
        ),
      props: true,
      meta: {
        page_title: i18n.global.tc('Thank You'),
        breadcrumbItems: ['categories-list', 'thankyou'],
        plugin: 'events',
      },
    },
    {
      path: 'waitingzone',
      name: 'waitingzone',
      component: () => import('@/views/customer/TicketWaitZone'),
      props: true,
      meta: {
        page_title: i18n.global.tc('Please wait ...'),
        breadcrumbItems: ['customer-event-list', 'thankyou'],
        plugin: 'events',
      },
    },
    {
      path: ':category',
      name: 'customer-event-list',
      component: () =>
        import(
          /* webpackChunkName: "events-list" */ '@/views/customer/EventsList'
        ),
      meta: {
        plugin: 'events',
        hide_navbar: true,
      },
    },
    {
      path: 'event/:slug',
      name: 'customer-event-details',

      component: () =>
        import(
          /* webpackChunkName: "events-details" */ '@/views/customer/EventDetails'
        ),
      meta: {
        page_title: i18n.global.tc('Event details'),
        breadcrumbItems: ['customer-event-list', 'customer-event-details'],
        plugin: 'events',
      },
    },
    {
      path: 'not-found',
      name: 'page-404',
      component: () =>
        import(/* webpackChunkName: "page-404" */ '@/views/customer/404'),
    },
  ],
};
