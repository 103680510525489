<template>
  <router-view />
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
};
</script>

<style scoped></style>
