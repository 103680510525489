import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({ token: null, logout: null }),
  getters: {
    getToken: (state) => state.token,
    getLogout: (state) => state.logout,
  },
  actions: {
    setToken(val) {
      this.token = val;
    },
    setLogout(val) {
      this.logout = val;
    },
  },
});

export const useGlobalVariableStore = defineStore('global_variables', {
  state: () => ({ checkoutSDKLink: '', headerConfig: {} }),
  getters: {
    getCheckoutSDKLink: (state) => state.checkoutSDKLink,
    getHeaderConfig: (state) => state.headerConfig,
  },
  actions: {
    setCheckoutSDKLink(val) {
      this.checkoutSDKLink = val;
    },
    setHeaderConfig(val) {
      this.headerConfig = val;
    },
  },
});

export const useEventStore = defineStore('event', {
  state: () => ({
    eventDetails: {},
    eventImages: [],
    eventTickets: [],
    eventFields: [],
    eventTicketIndex: -1,
    uploadedImageStrs: {
      backgroundImageStr: '',
      bannerImageStr: '',
      calendarImageStr: '',
      ticketImageStr: [],
      eventImageStr: [],
    },
  }),
  getters: {
    getEventDetails: (state) => state.eventDetails,
    getEventImages: (state) => state.eventImages,
    getEventTickets: (state) => state.eventTickets,
    getEventFields: (state) => state.eventFields,
    getUploadedImageStrs: (state) => state.uploadedImageStrs,
    getEventTicketIndex: (state) => state.eventTicketIndex,
  },
  actions: {
    setEventDetails(val) {
      this.eventDetails = val;
    },
    setEventImages(val) {
      this.eventImages = val;
    },
    setEventTickets(val) {
      this.eventTickets = val;
    },
    setEventFields(val) {
      this.eventFields = val;
    },
    setUploadedImageStrs(val) {
      this.uploadedImageStrs = val;
    },
    appendSelectedCategories(val) {
      this.eventDetails.categories = [...this.eventDetails.categories, val];
    },
    resetEventDetails() {
      this.eventDetails = {};
    },
    resetEventImages() {
      this.eventImages = [];
    },
    resetEventTickets() {
      this.eventTickets = [];
    },
    resetEventFields() {
      this.eventFields = [];
    },
    setEventTicketIndex(val) {
      this.eventTicketIndex = val;
    },
    appendSelectedEventTime(val) {
      this.eventTickets[this.eventTicketIndex].event_time = [
        ...this.eventTickets[this.eventTicketIndex].event_time,
        val,
      ];
    },
    resetUploadedImageStrs() {
      this.uploadedImageStrs = {
        backgroundImageStr: '',
        bannerImageStr: '',
        calendarImageStr: '',
        ticketImageStr: [],
        eventImageStr: [],
      };
    },
  },
});
