import SubHome from '../views/SubHome';
import { i18n } from '@/plugins/i18n';

export default {
  path: 'event',
  component: SubHome,
  children: [
    {
      path: 'event-category-list',
      name: 'event-category-list',
      component: () =>
        import(
          /* webpackChunkName: "EventCategoryList" */ '../views/dashboard/EventCategoryList'
        ),
      meta: {
        page_title: i18n.global.t('Event Categories'),
        breadcrumb_items: ['general-info', 'event-category-list'],
      },
    },
    {
      path: 'add-event-category',
      name: 'add-event-category',
      component: () =>
        import(
          /* webpackChunkName: "AddEventCategory" */ '../views/dashboard/AddEventCategory'
        ),
      meta: {
        page_title: i18n.global.t('Add Event Category'),
        breadcrumb_items: [
          'general-info',
          'event-category-list',
          'add-event-category',
        ],
      },
    },
    {
      path: 'edit-event-category/:id',
      name: 'edit-event-category',
      component: () =>
        import(
          /* webpackChunkName: "AddEventCategory" */ '../views/dashboard/AddEventCategory'
        ),
      meta: {
        page_title: i18n.global.t('Edit Event Category'),
        breadcrumb_items: [
          'general-info',
          'event-category-list',
          'edit-event-category',
        ],
      },
    },
    {
      path: 'event-subticket-list',
      name: 'event-subticket-list',
      component: () =>
        import(
          /* webpackChunkName: "EventSubTicketList" */ '../views/dashboard/EventSubTicketList'
        ),
      meta: {
        page_title: i18n.global.t('Event Subtickets'),
        breadcrumb_items: ['general-info', 'event-subticket-list'],
      },
    },
    {
      path: 'add-event-subticket',
      name: 'add-event-subticket',
      component: () =>
        import(
          /* webpackChunkName: "AddEventSubTicket" */ '../views/dashboard/AddEventSubTicket'
        ),
      meta: {
        page_title: i18n.global.t('Add Event Subticket'),
        breadcrumb_items: [
          'general-info',
          'event-subticket-list',
          'add-event-subticket',
        ],
      },
    },
    {
      path: 'edit_event_subticket/:id',
      name: 'edit_event_subticket',
      component: () =>
        import(
          /* webpackChunkName: "AddEventSubTicket" */ '../views/dashboard/AddEventSubTicket'
        ),
      meta: {
        page_title: i18n.global.t('Edit Event Subticket'),
        breadcrumb_items: [
          'general-info',
          'event-subticket-list',
          'edit_event_subticket',
        ],
      },
    },
    {
      path: 'event-list',
      name: 'event-list',
      component: () =>
        import(
          /* webpackChunkName: "Events" */ '../views/dashboard/EventsList'
        ),
      meta: {
        page_title: i18n.global.t('Events'),
        breadcrumb_items: ['general-info', 'event-list'],
      },
    },
    {
      path: 'add-event',
      name: 'add-event',
      component: () =>
        import(/* webpackChunkName: "Events" */ '../views/dashboard/AddEvent'),
      meta: {
        page_title: i18n.global.t('Add Event'),
        breadcrumb_items: ['general-info', 'event-list', 'add-event'],
      },
    },
    {
      path: 'event-list/:slug',
      name: 'event-details',
      component: () =>
        import(/* webpackChunkName: "Events" */ '../views/dashboard/AddEvent'),
      meta: {
        page_title: i18n.global.t('Edit Event'),
        breadcrumb_items: ['general-info', 'event-list', 'event-details'],
      },
    },
    {
      path: 'event-time-list',
      name: 'event-time-list',
      component: () =>
        import(
          /* webpackChunkName: "EventTimeList" */ '../views/dashboard/EventTimeList'
        ),
      meta: {
        page_title: i18n.global.t('Event Times'),
        breadcrumb_items: ['general-info', 'event-time-list'],
      },
    },
    {
      path: 'add-event-time',
      name: 'add-event-time',
      component: () =>
        import(
          /* webpackChunkName: "AddEventTime" */ '../views/dashboard/AddEventTime'
        ),
      meta: {
        page_title: i18n.global.t('Add Event Time'),
        breadcrumb_items: ['general-info', 'event-time-list', 'add-event-time'],
      },
    },
    {
      path: 'edit-event-time/:id',
      name: 'edit-event-time',
      component: () =>
        import(
          /* webpackChunkName: "AddEventTime" */ '../views/dashboard/AddEventTime'
        ),
      meta: {
        page_title: i18n.global.t('Edit Event Time'),
        breadcrumb_items: [
          'general-info',
          'event-time-list',
          'edit-event-time',
        ],
      },
    },
    {
      path: 'fulfillment',
      name: 'fulfillment',
      component: () =>
        import(
          /* webpackChunkName: "Bookings" */ '../views/dashboard/Fulfillment'
        ),
      meta: {
        page_title: i18n.global.t('Fulfillment'),
        breadcrumb_items: ['general-info', 'event', 'fulfillment'],
        plugin: 'event',
      },
    },
    {
      path: 'bookings',
      name: 'bookings',
      component: () =>
        import(
          /* webpackChunkName: "Bookings" */ '../views/dashboard/BookingsList'
        ),
      meta: {
        page_title: i18n.global.t('Bookings'),
        breadcrumb_items: ['general-info', 'event', 'bookings'],
        plugin: 'event',
      },
    },
    {
      path: 'bookings/:id',
      name: 'booking-details',
      component: () =>
        import(
          /* webpackChunkName: "Bookings" */ '../views/dashboard/BookingDetails'
        ),
      meta: {
        plugin: 'event',
      },
    },
    {
      path: 'create',
      name: 'booking-create',
      component: () =>
        import(
          /* webpackChunkName: "Bookings" */ '../views/dashboard/BookingCreate'
        ),
      meta: {
        page_title: i18n.global.t('Create Bookings'),
        breadcrumb_items: ['general-info', 'bookings', 'booking-create'],
        plugin: 'event',
      },
    },
    {
      path: 'plugin-config',
      name: 'plugin-config',
      component: () =>
        import(
          /* webpackChunkName: "Events" */ '../views/dashboard/PluginConfig'
        ),
      meta: {
        page_title: i18n.global.t('Event Configuration'),
        breadcrumb_items: ['general-info', 'plugin-config'],
      },
    },
  ],
};
