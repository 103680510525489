<template>
  <v-container>
    <v-row>
      <v-col offset="4">
        <h1>{{ $t('Page not found') }}</h1>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped></style>
