<template>
  <div id="vuetify-3">
    <v-app id="event_frontend" :class="classes">
      <router-view />
      <Snackbar></Snackbar>
    </v-app>
  </div>
</template>

<script setup>
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { i18n } from './plugins/i18n';
import { useAuthStore } from '@/store/index';
import { useGlobalVariableStore } from '@/store/index';

const props = defineProps([
  'idToken',
  'logout',
  'sentryUrl',
  'sentrySampleRate',
  'ignoredSentryErrors',
  'checkSentryErrors',
  'isLocal',
  'translations',
  'arabicTranslation',
  'noNavBar',
  'checkoutSDKLink',
]);
Sentry.setTag('microfrontend', 'event_frontend');
Sentry.init({
  dsn:
    props.sentryUrl ||
    'https://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10',
  integrations: [new Integrations.Vue({ attachProps: true, logErrors: true })],
  ignoreErrors: props.ignoredSentryErrors,
  beforeSend: props.checkSentryErrors,
  sampleRate: props.sentrySampleRate,
  autoSessionTracking: false,
  enabled: !props.isLocal,
});
const authStore = useAuthStore();
authStore.setToken(props.idToken);
authStore.setLogout(props.logout);

const globalVariableStore = useGlobalVariableStore();
globalVariableStore.setCheckoutSDKLink(props.checkoutSDKLink);
</script>
<script>
import Snackbar from './components/Snackbar';

export default {
  components: { Snackbar },
  mounted() {
    this.setLang();
  },
  watch: {
    '$route.params.lang': function (value) {
      if (value === 'en' || value === 'ar') {
        i18n.global.locale = value;
        this.$vuetify.locale.current = i18n.global.locale;
      }
    },
  },
  computed: {
    classes() {
      return !this.noNavBar && 'navbarShow p-b-50 p-t-90';
    },
  },
  methods: {
    setLang() {
      const translations = this.translations();
      i18n.global.messages['en'] = translations.en || {};
      i18n.global.messages['ar'] = this.arabicTranslation || {};
    },
  },
};
</script>

<style scoped lang="scss">
.p-b-50 {
  padding-bottom: 50px;
}
.p-t-90 {
  padding-top: 90px;
}
.navbarShow {
  padding-left: 300px;
  @media screen and (max-width: 1263px) {
    padding-left: 0;
  }
}
.v-locale--is-rtl.navbarShow {
  padding-left: 0px;
  padding-right: 300px;
  @media screen and (max-width: 1263px) {
    padding-right: 0;
  }
}
div >>> .v-text-field__details {
  padding-bottom: 5px;
}
.v-theme--ottuEventTheme {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f3f3f3;
  --v-hover-opacity: 0.04;
  --v-theme-overlay-multiplier: 1;
  /*margin-top: 60px;*/
}
</style>
