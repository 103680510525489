import { createVuetify } from 'vuetify';
import * as directives from 'vuetify/directives';
import * as components from 'vuetify/components';

const ottuEventTheme = {
  dark: false,
  colors: {
    primary: '#f77c1a',
    success: '#4caf50',
    error: '#ff5252',
  },
};

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'ottuEventTheme',
    themes: {
      ottuEventTheme,
    },
  },
  defaults: {
    VAutocomplete: { variant: 'underlined', hideDetails: 'auto' },
    VSelect: { variant: 'underlined', hideDetails: 'auto' },
    VTextarea: { variant: 'outlined', hideDetails: 'auto' },
    VTextField: {
      variant: 'underlined',
      density: 'compact',
      hideDetails: 'auto',
    },
  },
});

export default vuetify;
