import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import NotFound from '../views/NotFound.vue';
import customerRoutes from './customer';
import dashboardRoutes from './dashboard';
import SubHome from '../views/SubHome';
import { i18n } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { useAuthStore } from '@/store';

const routes = [
  {
    path: '/:lang',
    name: 'home',
    component: Home,
    children: [
      dashboardRoutes,
      customerRoutes,
      {
        path: ':pathMatch(.*)*',
        name: '404',
        component: NotFound,
      },
      {
        path: 'dashboard',
        name: 'general-info',
        component: SubHome,
        meta: {
          requiresAuth: true,
          page_title: 'Dashboard',
        },
      },
      {
        path: 'event',
        name: 'event',
        component: SubHome,
        meta: {
          // page_title: i18n.tc("Events"),
          requiresAuth: true,
          is_wrapper: true,
          plugin: 'event',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // i18n logic ::::::::::::::::::::::::::::::::::::::::::::
  const lang = to.params.lang;
  if (i18n.locale !== lang) i18n.global.locale = lang;
  vuetify.isRtl = i18n.global.locale === 'ar';
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authStore = useAuthStore();
    if (!authStore.getToken()) {
      authStore.getLogout();
      return;
    }
  }
  return next();
});

export default router;
